/** Swiper Custom Css **/
.swiper-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 40px;
}

.swiper-pagination-bullet {
  width: 5px;
  height: 5px;
  background-color: #8C8C8C;
  opacity: 1;
  transition-property: width, height, background-color;
  transition-duration: 0.5s, 0.5s, 0.5s;
  transition-timing-function: ease-in-out;
}

.swiper-pagination-bullet-active {
  width: 10px;
  height: 10px;
  background-color: #DF0618;
  transition-property: width, height, background-color;
  transition-duration: 0.5s, 0.5s, 0.5s;
  transition-timing-function: ease-in-out;
}

.swiper-button-next, .swiper-button-prev {
  padding: 0 5%;
  color: #FFFFFF;
}

.swiper-button-next:hover, .swiper-button-prev:hover {
  color: #DF0618;
  transition-duration: 0.5s;
}

@media screen and (max-width: 767px) {
  .swiper-pagination {
    padding-right: 2%;
    flex-direction: column;
  }

  .swiper-pagination-bullet {
    width: 7px;
    height: 7px;
    border: 1px solid #8C8C8C;
    background: none;
  }

  .swiper-pagination-bullet-active {
    border: none;
    background: #DF0618;
  }

  .swiper-button-next, .swiper-button-prev {
    display: none;
  }
}

/* Custom Css */
.text-border-red {
  text-shadow: 0px 0px 6px #DF0618;
}

.timeline-hr {
  border: 1px solid transparent;
  border-image: linear-gradient(to top, #FFFFFF 70%, #000000 100%);
  border-image-slice: 1;
}