@tailwind base;

@layer base {
  :root {
    --sat: env(safe-area-inset-top);
    --sar: env(safe-area-inset-right);
    --sab: env(safe-area-inset-bottom);
    --sal: env(safe-area-inset-left);
  }

  body {
    /*@apply font-suite;*/
    font-family: 'SUIT', sans-serif;
    font-style: normal;
    font-weight: 300;
    color: #FFFFFF;
    background: #000000;
  }

  p {
    margin: 0;
  }
}

@tailwind components;
@tailwind utilities;

/*@import url(https://cdn.jsdelivr.net/gh/sun-typeface/SUITE/fonts/static/woff2/SUITE.css);*/

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'SUIT';
  font-style: normal;
  font-weight: 100;
  src: url('./fonts/SUIT/SUIT-Thin.ttf') format('truetype'),
    url('./fonts/SUIT-Thin.woff') format('woff');
}

@font-face {
  font-family: 'SUIT';
  font-style: normal;
  font-weight: 200;
  src: url('./fonts/SUIT/SUIT-ExtraLight.ttf') format('truetype'),
    url('./fonts/SUIT-ExtraLight.woff') format('woff');
}

@font-face {
  font-family: 'SUIT';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/SUIT/SUIT-Light.otf') format('opentype'),
    url('./fonts/SUIT-Light.woff') format('woff');
}

@font-face {
  font-family: 'SUIT';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/SUIT/SUIT-Regular.otf') format('opentype'),
    url('./fonts/SUIT-Regular.woff') format('woff');
  /* src: url('./fonts/SUIT-Regular.woff') format('woff'); */
}

@font-face {
  font-family: 'SUIT';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/SUIT/SUIT-Medium.ttf') format('truetype'),
    url('./fonts/SUIT-Medium.woff') format('woff');
}

@font-face {
  font-family: 'SUIT';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/SUIT/SUIT-SemiBold.otf') format('opentype'),
    url('./fonts/SUIT-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'SUIT';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/SUIT/SUIT-Bold.ttf') format('truetype'),
    url('./fonts/SUIT-Bold.woff') format('woff');
}

@font-face {
  font-family: 'SUIT';
  font-style: normal;
  font-weight: 800;
  src: url('./fonts/SUIT/SUIT-ExtraBold.otf') format('opentype'),
    url('./fonts/SUIT-ExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'SUIT';
  font-style: normal;
  font-weight: 900;
  src: url('./fonts/SUIT/SUIT-Heavy.otf') format('opentype'),
    url('./fonts/SUIT-Heavy.woff') format('woff');
}

.mySwiper .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 80px;
  z-index: 999;
}